import React from "react";
import { Outlet } from "react-router";
import { Link, useLocation } from "react-router-dom";
import useViewport from "hooks/useViewport";
import LayoutHeader from "./LayoutHeader";
import LayoutMenu from "./LayoutMenu";
import LayoutFooter from "./LayoutFooter";
import { Layout as AntLayout } from "antd";
import brandLogo from "images/CSARLogo.png";

import "styles/layout.css";
import "styles/application.css";
import CuiBanner from "components/Common/CUIBanner";
const { Content, Sider, Header } = AntLayout;

interface Props {
  items: Array<any>;
}

const Layout: React.FC<Props> = ({ items }) => {
  const location = useLocation();
  const viewport = useViewport();

  const isAdminMode = location.pathname.split("/")[1] === "administration";

  const brandLinkTo = isAdminMode ? "/administration" : "/home";

  if (location.pathname.split("/")[4] === "generate") return <Outlet />;

  return (
    <AntLayout className="layout">
      <CuiBanner />
      <AntLayout>
        <Sider
          className={viewport.layout.cns.sider}
          collapsed={viewport.layout.collapsed}
          collapsedWidth={60}
          style={{ zIndex: 1000 }}
        >
          <Link to={brandLinkTo}>
            <img src={brandLogo} className={viewport.layout.cns.brand} alt="CSAR brand logo" />
          </Link>
          <LayoutMenu items={items} />
        </Sider>
        <AntLayout>
          <LayoutHeader />
          <Content className={viewport.layout.cns.content}>
            <Outlet />
          </Content>
          <LayoutFooter />
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
