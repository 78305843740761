import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import React from "react";
import "../../styles/cuiBanner.css";
interface Props {}

const CuiBanner: React.FC<Props> = (props) => {
  return (
    <Alert
      message="UNCLASSIFIED / NO CUI"
      style={{
        fontWeight: "bold",
        backgroundColor: "#007A33",
        borderColor: "#007A33",
        borderRadius: 0,
        height: 20,
        color: "white",
      }}
      type="info"
      showIcon={false}
      banner
      className="cui-banner"
    />
  );
};

export default CuiBanner;
